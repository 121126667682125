import $$ from 'dom7';
import rivets from 'rivets';
import moment from 'moment';
import data from './work.f7.html';
export default {
  data: function () {
    return {
      work_id: null,
      inwork: false,
      item: {},
      products: [],
      products_amoun: null
    };
  },
  on: {
    pageBeforeIn: function (e, page) {
      this.loadData();
    }
  },
  mounted: function () {
    moment.locale('ru');
    rivets.configure({
      templateDelimiters: ['%%', '%%']
    });

    rivets.formatters.phones = function (value) {
      if (value) return value.split(';').map(phone => '<a class="external" href="tel:' + phone.trim().replace(/[^0-9]/g, '') + '">' + phone.trim() + '</a>').join(", ");
    };

    rivets.formatters.dt = function (value, format) {
      return moment(value).format(format);
    };

    rivets.bind(this.el, this);
  },
  methods: {
    loadData: function () {
      this.work_id = this.$route.params.work_id;
      this.$root.get('https://to.gloud.com.ua/ds/v_works/' + this.work_id, {}).then(item => {
        this.$root.get('https://to.gloud.com.ua/ds/clients/' + item.client_id, {}).then(client => {
          this.$root.get('https://to.gloud.com.ua/ds/v_work_products', {
            q: ["works_id = " + this.work_id]
          }).then(products => {
            this.item = data.item = item;
            this.client = data.client = client;
            this.inwork = item.state != "inwork";
            this.iscomplete = item.status_id != 13;
            data.products = products.items;
            data.products_amount = products.items.reduce((amount, product) => {
              return Math.round(amount + (product.vip_price > 0 ? product.vip_price : product.price) * product.count);
            }, 0);
            this.render();
          });
        });
      });
    },

    doinwork() {
      let self = this;
      this.$app.dialog.confirm('ГОТОВ k ТО?', function () {
        self.$root.put('https://to.gloud.com.ua/ds/works/' + self.work_id, {
          state: "inwork"
        }).then(item => {
          // self.$app.dialog.alert('Сохранили');
          this.inwork = false;
          self.loadData();
        });
      });
    },

    render: function () {
      let self = this;
      this.$el.find(".page-content").html(data.render());
    },
    edit: function (e, page) {
      console.log($$(e.target), $$(e.target).dataset());
      page.$router.navigate('/edit/' + $$(e.target).dataset().workId);
    },
    complete: function (e, page) {
      let self = this;
      this.$app.dialog.confirm('Выполнил и закрыть?', function () {
        self.$root.put('https://to.gloud.com.ua/ds/works/' + self.work_id, {
          status_id: 11
        }).then(item => {// self.$app.dialog.alert('Сохранили');
        });
      });
    }
  },
  id: '59bd8cfa29',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class="navbar text-color-white bg-color-deeppurple"><div class="navbar-inner sliding"><div class=left><a href=# class="link panel-open text-color-white" data-panel=left><i class="icon f7-icons">menu</i> <img style="height: 40px;" src=/static/logo-tom.png></a></div></div></div><div class="toolbar toolbar-top color-theme-deeppurple"><div class=toolbar-inner><a href=# class="link back uppercase"><i class=f7-icons>chevron_left</i> <span rv-text=item.title></span></a></div></div><div rv-hide=iscomplete class="toolbar toolbar-bottom"><div class="toolbar-inner justify-content-space-around"><i rv-on-click=doinwork rv-show=inwork class="text-green-700 f7-icons font-bold align-middle">wrench</i> <i rv-on-click=complete rv-hide=inwork class="text-color-teal f7-icons font-bold align-middle">checkmark_alt_circle</i> <i rv-on-click=edit rv-data-work-id=item.id class="text-color-blue f7-icons font-bold align-middle">square_pencil</i></div></div><div class=page-content></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};