
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();
    return {
      eventItems: [],
      today: new Date(year, month, day)
    };
  },
  methods: {
    renderEvents: function (calendar) {
      var self = this;
      var currentDate = calendar.value[0];
      var currentEvents = self.events.filter(function (event) {
        return event.date.getTime() >= currentDate.getTime() && event.date.getTime() < currentDate.getTime() + 24 * 60 * 60 * 1000;
      });
      const eventItems = [];

      if (currentEvents.length) {
        currentEvents.forEach(function (event) {
          const hours = event.hours;
          let minutes = event.minutes;
          if (minutes < 10) minutes = `0${minutes}`;
          eventItems.push({
            title: event.title,
            time: event.hours + ":" + event.minutes,
            color: event.color,
            evt: event.evt,
            work_id: event.work_id,
            client: event.client,
            address: event.address
          });
        });
      }

      self.$setState({
        eventItems: eventItems
      });
    }
  },
  on: {
    pageInit: function (e, page) {
      var self = this;
      var app = self.$app;
      var $ = self.$;
      var monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
      self.calendar = app.calendar.create({
        containerEl: '#calendar',
        toolbar: false,
        locale: "ru",
        value: [self.today],
        events: self.events,
        on: {
          init: function (calendar) {
            $('.navbar-calendar-title').text(monthNames[calendar.currentMonth] + ', ' + calendar.currentYear);
            app.navbar.size(app.navbar.getElByPage(page.el));
            calendar.$el.addClass('no-safe-area-right');
            self.renderEvents(calendar);
          },
          monthYearChangeStart: function (calendar) {
            $('.navbar-calendar-title').text(monthNames[calendar.currentMonth] + ', ' + calendar.currentYear);
            app.navbar.size(app.navbar.getElByPage(page.el));
          },
          change: function (calendar) {
            self.renderEvents(calendar);
          }
        }
      });
    },

    pageBeforeRemove() {
      var self = this;
      self.calendar.destroy();
    }

  },
  id: 'c232becbaa',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class="navbar text-color-white bg-color-deeppurple"><div class="navbar-inner sliding"><div class=left><a href=# class="link panel-open text-color-white" data-panel=left><i class="icon f7-icons">menu</i> <img style="height: 40px;" src=/static/logo-tom.png></a></div></div></div><div class="toolbar toolbar-top color-theme-deeppurple"><div class=toolbar-inner><a href=# class="link back uppercase"><i class=f7-icons>chevron_left</i> <span class="title navbar-calendar-title"></span></a></div></div><div class=page-content><div id=calendar class="block block-strong no-padding no-margin no-hairline-top"></div><div id=calendar-events class="list no-margin no-hairlines no-safe-area-left"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.eventItems, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.evt, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><a href=/work/';
              r += c(ctx_3.work_id, ctx_3);
              r += ' class="item-link item-content"><div class=event-color style="background-color: ';
              r += c(ctx_3.color, ctx_3);
              r += '"></div><div class=item-inner><div class=item-title><div class=item-title>';
              r += c(ctx_3.title, ctx_3);
              r += '</div><div class=item-footer><i class="f7-icons text-sm text-color-deeppurple">person_fill</i> ';
              r += c(ctx_3.client, ctx_3);
              r += '</div><div class=item-footer><i class="f7-icons text-sm text-color-deeppurple">placemark_fill</i> ';
              r += c(ctx_3.address, ctx_3);
              r += '</div></div><div class=item-after>';
              r += c(ctx_3.time, ctx_3);
              r += '</div></div></a></li>';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '<li class=item-content><div class=event-color style="background-color: ';
              r += c(ctx_3.color, ctx_3);
              r += '"></div><div class=item-inner><div class=item-title>';
              r += c(ctx_3.title, ctx_3);
              r += '</div></div></li>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<li class=item-content><div class=item-inner><div class="item-title text-color-gray">No events for this day</div></div></li>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div>';
      return r;
    }(this);
  },

  style: `
  #calendar,
  #calendar-events {
    height: 50%;
    box-sizing: border-box;
  }

  #calendar .calendar {
    height: 100%;
  }

  #calendar-events ul {
    height: 100%;
    overflow: auto;
  }

  #calendar-events .event-color {
    position: absolute;
    left: 0;
    top: 0;
    width: 8px;
    height: 100%;
  }

  @media (orientation: landscape) {
    #calendar {
      float: left;
    }

    #calendar,
    #calendar-events {
      height: 100%;
    }

    #calendar,
    #calendar-events {
      width: 50%;
    }

    #calendar-events {
      margin-left: 50% !important;
      border-left: 1px solid #eee;
    }

    .theme-dark #calendar-events {
      border-left-color: #282828;
    }
  }
`,
  styleScoped: false
};
    