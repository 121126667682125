
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  mounted() {
    let self = this;
    var pb = this.$app.photoBrowser.create({
      photos: this.item.images.map(img => "http://to.gloud.com.ua" + img.url),
      type: 'page'
    });
    this.$$('.pb-page').on('click', function () {
      pb.open();
    });
    this.$$('#camera').on('change', function () {
      let camera = this;

      if (camera.files && camera.files[0]) {
        let reader = new FileReader();

        reader.onload = function (e) {
          self.item.images.push({
            image: e.target.result,
            name: camera.files[0].name,
            title: null
          });
          self.$root.put('https://to.gloud.com.ua/ds/clients/' + self.client_id, {
            images: self.item.images
          }).then(item => {
            self.$root.get('https://to.gloud.com.ua/ds/clients/' + item.id, {}).then(client => {
              self.item = client;
              pb.destroy();
              pb = self.$app.photoBrowser.create({
                photos: self.item.images.map(img => "http://to.gloud.com.ua" + img.url),
                type: 'page'
              });
            });
          });
        };

        reader.readAsDataURL(camera.files[0]);
      }
    });
    this.$$('#gallery').on('change', function () {
      let camera = this;

      if (camera.files && camera.files[0]) {
        let reader = new FileReader();

        reader.onload = function (e) {
          self.item.images.push({
            image: e.target.result,
            name: camera.files[0].name,
            title: null
          });
          self.$root.put('https://to.gloud.com.ua/ds/clients/' + self.client_id, {
            images: self.item.images
          }).then(item => {
            self.$root.get('https://to.gloud.com.ua/ds/clients/' + item.id, {}).then(client => {
              self.item = client;
              pb.destroy();
              pb = self.$app.photoBrowser.create({
                photos: self.item.images.map(img => "http://to.gloud.com.ua" + img.url),
                type: 'page'
              });
            });
          });
        };

        reader.readAsDataURL(camera.files[0]);
      }
    });
  },

  id: '4039fb5721',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><input type=file accept=image/* id=camera style="display: none;" capture> <input type=file accept=image/* id=gallery style="display: none;"><div class="navbar text-color-white bg-color-deeppurple"><div class="navbar-inner sliding"><div class=left><a href=# class="link panel-open text-color-white" data-panel=left><i class="icon f7-icons">menu</i> <img style="height: 40px;" src=/static/logo-tom.png></a></div></div></div><div class="toolbar toolbar-top color-theme-deeppurple"><div class="toolbar-inner uppercase justify-start text-color-deeppurple"><a href=# class="link back"><i class="icon f7-icons">chevron_left</i></a> ';
      r += c(ctx_1.item.name, ctx_1);
      r += '</div></div><div class=page-content><div class="list accordion-list mt-0 mb-2"><ul><li class=accordion-item><a href=# class="item-content item-link"><div class=item-inner><div class="item-title text-color-deeppurple">оборудование</div><!-- <div class="item-after">\n                  <span class="badge padding-horizontal color-blue">';
      r += c(ctx_1.products.length, ctx_1);
      r += '</span>\n                </div> --></div></a><div class=accordion-item-content><div class=data-table><table><thead><tr><th class=label-cell>оборудование</th><th class=numeric-cell>кол-во</th></tr></thead><tbody>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.products, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<tr><td class=label-cell>';
          r += c(ctx_2.title, ctx_2);
          r += '</td><td class=numeric-cell>';
          r += c(ctx_2.count, ctx_2);
          r += '</td></tr>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</tbody></table></div></div></li></ul></div><div class="grid grid-cols-3 gap-2 px-3"><a href=# class="button button-fill icon-only pb-page"><i class=f7-icons style="margin-top: 5px;">eye</i> </a><a href=# class="button button-fill icon-only" onclick="document.getElementById(\'gallery\').click()"><i class=f7-icons style="margin-top: 5px;">photo_fill_on_rectangle_fill</i> </a><a href=# class="button button-fill icon-only" onclick="document.getElementById(\'camera\').click()"><i class=f7-icons style="margin-top: 5px;">camera-fill</i></a></div><div class="list simple-list mt-2 mb-0"><ul><li class="item-title text-color-deeppurple">история ТО</li></ul></div>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.history, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=card><div class=card-header>';
          r += Template7Helpers.dt.call(ctx_2, ctx_2.due_date, 'DD.MM.YYYY', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += c(ctx_2.title, ctx_2);
          r += '</div><div class=card-content><div class=block><strong>мастер:</strong> ';
          r += c(ctx_2.master, ctx_2);
          r += '</div><div class=block><strong>статус:</strong> <i class="icon f7-icons" style=color:';
          r += c(ctx_2.status_color, ctx_2);
          r += ';>checkmark_seal_fill</i> ';
          r += c(ctx_2.status, ctx_2);
          r += '</div><div class="list accordion-list"><ul><li class=accordion-item><a href=# class="item-content item-link"><div class=item-inner><div class=item-title>расходники</div><div class=item-after><span class="badge padding-horizontal color-blue">';
          r += c(ctx_2.products.length, ctx_2);
          r += '</span></div></div></a><div class=accordion-item-content><div class=data-table><table><thead><tr><th class=label-cell>материал</th><th class=numeric-cell>кол-во</th><th class=numeric-cell>цена &#8372;</th></tr></thead><tbody>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.products, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<tr><td class=label-cell>';
              r += c(ctx_3.title, ctx_3);
              r += '</td><td class=numeric-cell>';
              r += c(ctx_3.count, ctx_3);
              r += '</td><td class=numeric-cell>';
              r += c(ctx_3.price, ctx_3);
              r += '</td></tr>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</tbody></table></div></div></li></ul></div><div class=block><strong>стоимость расходников:</strong> ';
          r += Template7Helpers.call.call(ctx_2, ctx_1.amount, ctx_2.products, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' &#8372;</div><div class=block><strong>цена работ по ТО:</strong> ';
          r += c(ctx_2.cost, ctx_2);
          r += ' &#8372;</div><div class=block><strong>коментарий и доп.расходы:</strong> ';
          r += c(ctx_2.additional_expenses, ctx_2);
          r += '</div><div class=block><strong>доп.расх.сумма:</strong> ';
          r += c(ctx_2.additional_expenses_cost, ctx_2);
          r += ' &#8372;</div></div><div class=card-footer><strong>ИТОГО к оплате:</strong> ';
          r += Template7Helpers.js.call(ctx_2, "console.log(parent.amount)", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js.call(ctx_2, "parseFloat(this.additional_expenses_cost) + parseFloat(this.cost) + parent.amount(this.products)", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' &#8372;</div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    