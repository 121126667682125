
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '685cc0e022',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class="navbar text-color-white bg-color-deeppurple"><div class="navbar-inner sliding"><div class=left><a href=# class="link panel-open text-color-white" data-panel=left><i class="icon f7-icons">menu</i> <img style="height: 40px;" src=/static/logo-tom.png></a></div></div></div><div class="toolbar toolbar-top color-theme-deeppurple"><div class=toolbar-inner><a href=# class="link back uppercase"><i class=f7-icons>chevron_left</i> Инфо</a></div></div><div class=page-content><div class="list accordion-list"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.items, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li class=accordion-item><a href=# class="item-content item-link"><div class=item-inner><div class="font-bold item-title">';
          r += c(ctx_2.title, ctx_2);
          r += '</div></div></a><div class=accordion-item-content><div class=block><div data-pagination=\'{"el": ".swiper-pagination"}\' data-space-between=50 class="swiper-container swiper-init demo-swiper"><div class=swiper-pagination></div><div class=swiper-wrapper>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.images, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="swiper-slide h-40 flex justify-center"><img class=h-40 src="http://to.gloud.com.ua';
              r += c(ctx_3.url, ctx_3);
              r += '"></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div><p class="date text-color-gray">';
          r += Template7Helpers.dt.call(ctx_2, ctx_2.add_date, 'DD.MM.YYYY', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</p><p>';
          r += c(ctx_2.description, ctx_2);
          r += '</p></div></div></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div>';
      return r;
    }(this);
  },

  style: `
    .list .item-title {
        font-weight: 700;
    }
`,
  styleScoped: false
};
    