
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  state(item) {
    if (item.state == "inwork") {
      return "<i class='f7-icons text-sm text-color-deeppurple'>wrench_fill</i>: <span class='text-green-700'>ГОТОВ k ТО</span>";
    }

    return "<i class='f7-icons text-sm text-color-deeppurple'>logo_stackoverflow</i>: <span class='text-color-red'>КОМПЛЕКТАЦИЯ</span>";
  },

  paymentType(item) {
    if (!item.work_payment_type_id) return item.payment_type;
    return item.work_payment_type;
  },

  phones: function (item) {
    return item.phones.split(';').map(phone => '<a class="external underline text-color-blue text-bold" href="tel:' + phone.trim().replace(/[^0-9]/g, '') + '">' + phone.trim() + '</a>').join(", ");
  },
  price: function (product) {
    return Math.round(parseFloat(product.vip_price) > 0 ? parseFloat(product.vip_price) : parseFloat(product.price));
  },
  amount: function (product) {
    return Math.round(parseFloat(product.vip_price) > 0 ? parseFloat(product.vip_price) * parseFloat(product.count) : parseFloat(product.price) * parseFloat(product.count));
  },
  id: '902e0a1aef',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=card><!-- <div class="card-header">';
      r += c(ctx_1.item.title, ctx_1);
      r += '</div> --><div class=card-content><div class=block><i class="f7-icons text-base text-color-deeppurple">calendar_today</i> ';
      r += Template7Helpers.dt.call(ctx_1, ctx_1.item.due_date, 'DD.MM.YYYY', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' (';
      r += Template7Helpers.dt.call(ctx_1, ctx_1.item.due_date, 'dddd', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ') ';
      r += c(ctx_1.item.hour, ctx_1);
      r += ':';
      r += c(ctx_1.item.minute, ctx_1);
      r += '</div><div class=block><i class="f7-icons text-base text-color-deeppurple">person_fill</i>: ';
      r += c(ctx_1.item.client, ctx_1);
      r += '</div><div class=block><i class="f7-icons text-base text-color-deeppurple">phone_down_circle</i>: ';
      r += c(ctx_1.item.contacts, ctx_1);
      r += '</div><div class=block><i class="f7-icons text-base text-color-deeppurple">phone_down_circle_fill</i>: ';
      r += Template7Helpers.call.call(ctx_1, ctx_1.phones, ctx_1.item, {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class=block><i class="f7-icons text-base text-color-deeppurple">placemark_fill</i>: ';
      r += c(ctx_1.item.address, ctx_1);
      r += '</div><div class=block><i class="f7-icons text-base text-color-deeppurple">map_pin_ellipse</i>: <a href="http://www.google.com/maps/place/';
      r += c(ctx_1.item.gps, ctx_1);
      r += '" class="link external underline text-color-blue text-bold" target=_blank>';
      r += c(ctx_1.item.gps, ctx_1);
      r += '</a></div><div class=block><i class="f7-icons text-base text-color-deeppurple">exclamationmark_triangle</i>: <span class=text-color-red>';
      r += c(ctx_1.item.notes, ctx_1);
      r += '</span></div><div class=block><i class="f7-icons text-base text-color-deeppurple">folder_circle_fill</i>: <a href=/client/';
      r += c(ctx_1.item.client_id, ctx_1);
      r += ' class="underline text-color-blue text-bold">История ТО - ФОТО - Оборудование</a></div><div class=block>';
      r += Template7Helpers.call2.call(ctx_1, ctx_1.state, ctx_1.item, {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class="data-table margin-bottom"><table><thead><tr><th class=label-cell>расходник</th><th class=numeric-cell>кол-во</th><th class=numeric-cell>цена &#8372;</th><th class=numeric-cell>сумма &#8372;</th></tr></thead><tbody>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.products, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<tr><td class=label-cell>';
          r += c(ctx_2.title, ctx_2);
          r += '</td><td class=numeric-cell>';
          r += c(ctx_2.count, ctx_2);
          r += '</td><td class="numeric-cell whitespace-no-wrap">';
          r += Template7Helpers.call.call(ctx_2, ctx_1.price, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</td><td class="numeric-cell whitespace-no-wrap">';
          r += Template7Helpers.call.call(ctx_2, ctx_1.amount, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</td></tr>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</tbody></table></div><div class=block><strong class=text-color-deeppurple>цена расходников:</strong> ';
      r += c(ctx_1.products_amount, ctx_1);
      r += ' &#8372;</div><div class=block><strong class=text-color-deeppurple>цена работ по ТО:</strong> ';
      r += Template7Helpers.js.call(ctx_1, "Math.round(this.item.cost)", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' &#8372;</div><div class=block><strong class=text-color-deeppurple>коментарий и доп.расходы:</strong> ';
      r += c(ctx_1.item.additional_expenses, ctx_1);
      r += '</div><div class=block><strong class=text-color-deeppurple>доп. расх. сумма:</strong> ';
      r += Template7Helpers.js.call(ctx_1, "Math.round(this.item.additional_expenses_cost)", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' &#8372;</div><div class=block><strong class=text-color-deeppurple>форма оплаты:</strong> ';
      r += Template7Helpers.call.call(ctx_1, ctx_1.paymentType, ctx_1.item, {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div><div class="card-footer text-base text-color-black"><strong class=text-color-deeppurple>ИТОГО к оплате:</strong> <strong>';
      r += Template7Helpers.js.call(ctx_1, "Math.round(parseFloat(this.products_amount) + parseFloat(this.item.cost) + parseFloat(this.item.additional_expenses_cost))", {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' &#8372;</strong></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    