
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import moment from 'moment';
let wd = null;
export default {
  amount(work) {
    return Math.round(parseFloat(parseFloat(work.products_amount) + parseFloat(work.cost) + parseFloat(work.additional_expenses_cost)));
  },

  wd(due_date) {
    if (wd != moment(due_date).day()) {
      wd = moment(due_date).day();
      let s = "";

      switch (moment(due_date).format("dddd")) {
        case "понедельник":
          s = "<span class='badge font-bold bg-pink-400 px-2'>" + moment(due_date).format("dddd") + "</span>";
          break;

        case "вторник":
          s = "<span class='badge font-bold bg-purple-400 px-2'>" + moment(due_date).format("dddd") + "</span>";
          break;

        case "среда":
          s = "<span class='badge font-bold bg-indigo-400 px-2'>" + moment(due_date).format("dddd") + "</span>";
          break;

        case "четверг":
          s = "<span class='badge font-bold bg-blue-400 px-2'>" + moment(due_date).format("dddd") + "</span>";
          break;

        case "пятница":
          s = "<span class='badge font-bold bg-teal-400 px-2'>" + moment(due_date).format("dddd") + "</span>";
          break;

        case "суббота":
          s = "<span class='badge font-bold bg-green-400 px-2'>" + moment(due_date).format("dddd") + "</span>";
          break;

        case "воскресенье":
          s = "<span class='badge font-bold bg-yellow-400 px-2'>" + moment(due_date).format("dddd") + "</span>";
          break;
      }

      return s;
    }

    return "";
  },

  load: function (page) {
    wd = null; // return Promise.all([

    return page.$root.get('https://to.gloud.com.ua/ds/v_works', {
      q: ["master_id = " + page.$root.master_id, "status_id = 11|status_id = 29", "order desc due_date", "order asc hour", "limit 10"]
    }).then(data => {
      data.items.forEach(item => {
        //   page.$root.get('https://to.gloud.com.ua/ds/v_work_products', {
        //     q: ["works_id = " + item.id]
        //   }).then((products) => {
        //     item.products_amount = products.items.reduce((amount, product) => {
        //       return Math.round(amount + ((product.vip_price > 0 ? product.vip_price : product.price) * product.count));
        //     }, 0);
        //     item.amount =  Math.round(parseFloat(parseFloat(item.products_amount) + parseFloat(item.cost) + parseFloat(item.additional_expenses_cost)))
        //   });
        item.amount = Math.round(parseFloat(parseFloat(item.cost) + parseFloat(item.additional_expenses_cost)));
      });
      this.items = data.items;
    }); // ]).then((data) => {
    //   return data;
    // })
  },
  id: '15cd63f997',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=timeline>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.items, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.call2.call(ctx_2, ctx_1.wd, ctx_2.due_date, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<div class=timeline-item><div class=timeline-item-divider></div><div class="timeline-item-content w-full"><div class=timeline-item-inner><div class="timeline-item-time text-base"><i class="f7-icons text-sm text-color-deeppurple">calendar_today</i> ';
          r += Template7Helpers.dt.call(ctx_2, ctx_2.due_date, 'DD.MM.YYYY (dddd)', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += c(ctx_2.hour, ctx_2);
          r += ':';
          r += c(ctx_2.minute, ctx_2);
          r += '</div><div class=timeline-item-title><i class="f7-icons text-sm text-color-deeppurple">drop_fill</i> ';
          r += c(ctx_2.title, ctx_2);
          r += '</div><div class=timeline-item-subtitle><i class="f7-icons text-sm text-color-deeppurple">person_fill</i> <strong>';
          r += c(ctx_2.client, ctx_2);
          r += '</strong></div><div class=timeline-item-subtitle><i class="f7-icons text-sm text-color-deeppurple">placemark_fill</i> ';
          r += c(ctx_2.address, ctx_2);
          r += '</div><div class=timeline-item-subtitle><i class="icon f7-icons" style=color:#ccc;>checkmark_seal_fill</i> ';
          r += c(ctx_2.status, ctx_2);
          r += '</div><div class=timeline-item-text><a href=/work/';
          r += c(ctx_2.id, ctx_2);
          r += ' class=button>подробнее</a></div></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><!-- <div class="preloader infinite-scroll-preloader">\n    <span class="preloader-inner">\n      <span class="preloader-inner-gap"></span>\n      <span class="preloader-inner-left">\n        <span class="preloader-inner-half-circle"></span>\n      </span>\n      <span class="preloader-inner-right">\n        <span class="preloader-inner-half-circle"></span>\n      </span>\n    </span>\n  </div> -->';
      return r;
    }(this);
  },

  styleScoped: false
};
    