import $$ from 'dom7';
import rivets from 'rivets';
export default {
  data: function () {
    return {};
  },
  mounted: function () {
    rivets.configure({
      templateDelimiters: ['%%', '%%']
    });
    rivets.bind(this.el, this);
  },
  on: {
    // "smartselect:close": function (e, s) {
    //    this.item._payment_type = s.getValue();
    //  },
    pageBeforeIn: function (e, page) {
      this.loadData();
    }
  },
  methods: {
    paymentType() {
      if (!this.item.work_payment_type_id) return this.item.payment_type;
      return this.item.work_payment_type;
    },

    loadData() {
      this.$root.get('https://to.gloud.com.ua/ds/payment_types', {}).then(types => {
        this.types = types.items;
      });
    },

    save() {
      if (this.item.additional_expenses_cost > 0 && !this.item.additional_expenses) {
        this.$app.dialog.alert('заполните коментарий к доп.расходам');
        return;
      }

      this.$root.put('https://to.gloud.com.ua/ds/works/' + this.work_id, {
        payment_type_id: this.item.work_payment_type_id,
        additional_expenses: this.item.additional_expenses,
        additional_expenses_cost: this.item.additional_expenses_cost
      }).then(() => this.$router.back()
      /*this.$app.dialog.alert('сохранили')*/
      );
    },

    change(e, page) {
      let id = $$(e.target).dataset().productId;
      this.$root.put('https://to.gloud.com.ua/ds/work_products/' + id, {
        count: $$(e.target).val()
      });
    }

  },
  id: '0256092bdf',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class="navbar text-color-white bg-color-deeppurple"><div class="navbar-inner sliding"><div class=left><a href=# class="link panel-open text-color-white" data-panel=left><i class="icon f7-icons">menu</i> <img style="height: 40px;" src=/static/logo-tom.png></a></div></div></div><div class="toolbar toolbar-top color-theme-deeppurple"><div class=toolbar-inner><a href=# class="link back uppercase"><i class=f7-icons>chevron_left</i> ';
      r += c(ctx_1.item.title, ctx_1);
      r += '</a></div></div><div class="toolbar toolbar-bottom"><div class="toolbar-inner justify-content-space-around"><a rv-on-click=save class="button color-teal">сохранить</a></div></div><div class=page-content><div class="data-table margin-bottom"><table><thead><tr><th class=label-cell>расходник</th><th class=numeric-cell>кол-во</th></tr></thead><tbody><tr rv-each-product=products><td class=label-cell rv-text=product.title></td><td class=numeric-cell><div class="stepper stepper-init"><div class=stepper-button-minus></div><div class=stepper-input-wrap><input type=text rv-data-product-id=product.id rv-on-change=change rv-value=product.count min=0 max=100 step=1></div><div class=stepper-button-plus></div></div></td></tr></tbody></table></div><div class="list no-hairlines-md"><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">доп. расх. сумма</div><div class=item-input-wrap><input type=number rv-value=item.additional_expenses_cost placeholder="доп. расх. сумма" autocomplete=off></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label">коментарий и доп.расходы</div><div class=item-input-wrap><textarea rv-value=item.additional_expenses placeholder="дополнительные расходы"></textarea></div></div></li><li><a href=# class="item-link smart-select"><select name=fruits rv-value=item.work_payment_type_id><option rv-each-type=types rv-value=type.id rv-text=type.title></option></select><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">форма оплаты</div><div style="margin-left: 0;padding-left: 0;color: #000;" class=item-after rv-text="paymentType | call item.work_payment_type_id"></div></div></div></a></li></ul></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};