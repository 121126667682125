export default {
  on: {
    pageBeforeIn: function (e, page) {
      this.load();
    }
  },
  methods: {
    load: function () {
      if (this.content) this.content.load(this).then(() => {
        this.$el.find(".page-content").html(this.content.render());
      });
    }
  },
  id: '691d31f46b',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class="navbar text-color-white bg-color-deeppurple"><div class="navbar-inner sliding"><div class=left><a href=# class="link panel-open text-color-white" data-panel=left><i class="icon f7-icons mr-2">menu</i> <img style="height: 40px;" src=/static/logo-tom.png></a></div></div></div><div class="toolbar toolbar-top color-theme-deeppurple"><div class=toolbar-inner><a href=# class="link back" style="text-transform: uppercase;"><i class=f7-icons>chevron_left</i> ';
      r += c(ctx_1.title, ctx_1);
      r += '</a></div></div><div class="page-content infinite-scroll-content"></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};