export default {
  id: '3c87675825',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class="navbar text-color-white bg-color-deeppurple"><div class="navbar-inner sliding"><div class=left><a href=# class="link text-color-white"><img style="height: 40px;" src=/static/logo-tom.png></a></div></div></div><div class=page-content><div class=block><div class=list><ul><li><a href=/works class="item-link panel-close"><div class=item-content><div class=item-media><i class=f7-icons>square_list</i></div><div class=item-inner>Планы ТО в работе</div></div></a></li><li><a href=/complete-works class="item-link panel-close"><div class=item-content><div class=item-media><i class=f7-icons>square_list_fill</i></div><div class=item-inner>Выполненные ТО</div></div></a></li><li><a href=/calendar class="item-link panel-close"><div class=item-content><div class=item-media><i class=f7-icons>calendar</i></div><div class=item-inner>Календарь</div></div></a></li><li><a href=/info class="item-link panel-close"><div class=item-content><div class=item-media><i class=f7-icons>info</i></div><div class=item-inner>Инфо</div></div></a></li><li><a href=/logout class="item-link panel-close"><div class=item-content><div class=item-media><i class=f7-icons>square_arrow_left</i></div><div class=item-inner>Выход</div></div></a></li></ul></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};