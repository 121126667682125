
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '2898789c8f',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page><div class="navbar text-color-white bg-color-deeppurple"><div class="navbar-inner sliding"><div class=left><a href=# class="link panel-open text-color-white" data-panel=left><i class="icon f7-icons">menu</i> <img style="height: 40px;" src=/static/logo-tom.png></a></div></div></div><div class="toolbar toolbar-top color-theme-deeppurple"><div class=toolbar-inner><a href=# class="link back uppercase"><i class=f7-icons>chevron_left</i> Календарь</a></div></div><div class=page-content><div class="timeline timeline-horizontal col-50 tablet-15"><!-- Timeline Year --> ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.cal, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=timeline-year><!-- Timeline Year Title --><div class=timeline-year-title><span>';
          r += c(data_2 && data_2.key, ctx_2);
          r += '</span></div><!-- Timeline Month --> ';
          r += Template7Helpers.each.call(ctx_2, ctx_2, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class=timeline-month><!-- Timeline Month Title --><div class=timeline-month-title><span>';
              r += c(ctx_3.title, ctx_3);
              r += '</span></div><!-- Timeline Month Items --> ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.days, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class=timeline-item><div class=timeline-item-date>';
                  r += c(data_4 && data_4.key, ctx_4);
                  r += '</div><div class=timeline-item-content>';
                  r += Template7Helpers.each.call(ctx_4, ctx_4, {
                    hash: {},
                    data: data_4 || {},
                    fn: function (ctx_5, data_5) {
                      var r = '';
                      r += '<div class=timeline-item-time>';
                      r += c(ctx_5.hour, ctx_5);
                      r += ':';
                      r += c(ctx_5.minute, ctx_5);
                      r += '</div><div class=timeline-item-text><a href="/work/';
                      r += c(ctx_5.id, ctx_5);
                      r += '" class="underline text-color-blue text-bold link">';
                      r += c(ctx_5.title, ctx_5);
                      r += '</a></div><div class=timeline-item-text>';
                      r += c(ctx_5.client, ctx_5);
                      r += '</div><div class=timeline-item-text>';
                      r += c(ctx_5.address, ctx_5);
                      r += '</div><div class="timeline-item-text text-color-red border-b">';
                      r += c(ctx_5.special_notes, ctx_5);
                      r += '</div>';
                      return r;
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [ctx_3, ctx_2, ctx_1]
                  });
                  r += '</div></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div>';
      return r;
    }(this);
  },

  style: `
    .list .item-title {
        font-weight: 700;
    }
`,
  styleScoped: false
};
    