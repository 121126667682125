import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';
import Template7 from 'template7';
import rivets from 'rivets'
import moment from 'moment';

import 'framework7/css/framework7.bundle.min.css';
import 'framework7-icons';

import routes from './routes.js';

moment.locale('ru');

Template7.registerHelper('dt', function (date, format, options) {
  return moment(date).format(format);
});

Template7.registerHelper('call', function (fn, ctx, options) {
  return fn(ctx, options.hash, ...Object.values(options.hash));
});

Template7.registerHelper('call2', function (fn, ...args) {
  return fn(...args);
});

let app = new Framework7({
  root: '#app',
  id: 'gidra.gidro.net.ua',
  name: 'Gidro TOM',
  theme: 'md',
  clicks: {
    externalLinks: '.external',
  },
  routes: routes,
  serviceWorker: {
    path: '/service-worker.js',
  },
  dialog: {
    buttonOk: 'Да',
    buttonCancel: 'Нет'
  },
  data: function () {
    return {
      credentials: {
        name: null,
        password: null,
        enter: (e) => this.methods.enter(e)
      },
      user_id: null,
      master_id: null,
      token: null,
      refresh: null,
      exp: null
    };
  },
  methods: {
    enter(e) {
      let self = this;
      this.methods.post('https://to.gloud.com.ua/tom/login', {
        login: this.data.credentials.name,
        password: this.data.credentials.password,
      }).then(function (data, status, xhr) {
        let payload = JSON.parse(atob(data.token.split('.')[1]));
        self.data.user_id = payload.user_id;
        self.data.master_id = payload.master_id;
        self.data.user_role = payload.role;
        self.data.token = data.token;
        self.data.refresh = data.token;
        self.data.exp = data.exp;
        self.request.setup({
          headers: {
            'X-Auth-Token': self.data.token
          }
        });
        localStorage.setItem("gidraData", JSON.stringify(data));
        self.loginScreen.close("#login-screen");
      })
    },
    request: function (options) {
      let self = this;
      this.preloader.show();
      return new Promise((resolve, reject) => {
        options.error = function (xhr, status) {
          reject(xhr, status);
        };
        options.success = function (data, status, xhr) {
          resolve(data, status, xhr);
        };
        options.statusCode = {
          403: function (xhr) {
            self.preloader.hide();
            if (self.data.user_id) {
              self.loginScreen.open("#login-screen");
            } else {
              self.toast.create({
                icon: '<i class="material-icons">vpn_key</i>',
                text: xhr.response,
                position: 'top',
                closeTimeout: 2000,
              }).open();
            }
          },
          500: function (xhr) {
            self.preloader.hide();
            self.toast.create({
              icon: '<i class="f7-icons">alert</i>',
              text: xhr.response,
              position: 'top',
              closeTimeout: 2000,
            }).open();
          },
          200: function (xhr) {
            self.preloader.hide();
          },
        };
        self.request(options);
      });
    },
    get: function (url, data) {
      return this.methods.request({
        url: url,
        contentType: 'application/json',
        dataType: 'json',
        type: 'get',
        cache: false,
        crossDomain: true,
        data: data,
      })
    },
    put: function (url, data) {
      return this.methods.request({
        url: url,
        contentType: 'application/json',
        dataType: 'json',
        type: 'put',
        cache: false,
        crossDomain: true,
        data: data,
      })
    },
    post: function (url, data) {
      return this.methods.request({
        url: url,
        contentType: 'application/json',
        dataType: 'json',
        type: 'post',
        cache: false,
        crossDomain: true,
        data: data,
      })
    },
  },
  on: {
    click: function (e) {
      let data = $$(e.target).dataset();
      if (data.event)
        this.emit(data.event, e);
    },
    init: function () {
      if (localStorage.getItem("gidraData")) {
        let data = JSON.parse(localStorage.getItem("gidraData"));
        let payload = JSON.parse(atob(data.token.split('.')[1]));
        this.data.user_id = payload.user_id;
        this.data.master_id = payload.master_id;
        this.data.user_role = payload.role;
        this.data.token = data.token;
        this.data.refresh = data.token;
        this.data.exp = data.exp;
        this.request.setup({
          headers: {
            'X-Auth-Token': this.data.token
          }
        });
      }
      if (this.data.token == null)
        this.loginScreen.open("#login-screen");
      rivets.bind($$("#login-screen")[0], this.data.credentials);
    }
  }
});

let main = app.views.create('.view-main');