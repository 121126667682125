import $$ from "dom7";
import rivets from "rivets";
import view from "../pages/view.f7.html";
import index from "../pages/index.f7.html";
import works from "../pages/works.f7.html";
import completeWorks from "../pages/complete.works.f7.html";
import workView from "../pages/work.view.f7.html";
import client from "../pages/client.f7.html";
import edit from "../pages/edit.f7.html";
import info from "../pages/info.f7.html";
import calendar from "../pages/calendar.f7.html";
import calendar2 from "../pages/calendar2.f7.html";
import moment from "moment";

/*
import h from 'vhtml';

let items = ['one', 'two', 'three'];

const Item = ({ item, index, children }) => (
  <li id={index}>
    <h4>{item}</h4>
    {children}
  </li>
);

const profile = (items) => <div class="page">
  <h1>Hi!</h1>
  <ul>
    {items.map((item, index) => (
      <Item {...{ item, index }}>
        This is item {item}!
    </Item>
    ))}
  </ul>
</div>;

console.log(profile(items));
*/

function page(content, title) {
  return function (routeTo, routeFrom, resolve, reject) {
    resolve(
      {
        component: view,
      },
      {
        context: {
          title: title,
          content: content,
        },
      }
    );
  };
}

window.amount = function (products) {
  return products.reduce((amount, product) => {
    return Math.round(
      amount + parseFloat(product.price) * parseInt(product.count)
    );
  }, 0.0);
};

let routes = [
  {
    path: "/",
    component: index,
  },
  {
    path: "/works",
    async: page(works, "Планы ТО в работе"),
  },
  {
    path: "/complete-works",
    async: function (routeTo, routeFrom, resolve, reject) {
      resolve(
        {
          component: view,
        },
        {
          context: {
            title: "Выполненные ТО",
            content: completeWorks,
          },
        }
      );
    },
  },
  {
    path: "/client/:client_id",
    async: function (routeTo, routeFrom, resolve, reject) {
      this.app.methods
        .get(
          "https://to.gloud.com.ua/ds/clients/" + routeTo.params.client_id,
          {}
        )
        .then((item) => {
          this.app.methods
            .get("https://to.gloud.com.ua/ds/client_history", {
              q: [
                "client_id = " + routeTo.params.client_id,
                "status_id = 11|status_id = 29",
                "order desc due_date",
              ],
            })
            .then((history) => {
              this.app.methods
                .get("https://to.gloud.com.ua/ds/v_client_products", {
                  q: [
                    "client_id = " + routeTo.params.client_id,
                    "order asc title",
                  ],
                })
                .then((products) => {
                  resolve(
                    {
                      component: client,
                    },
                    {
                      context: {
                        client_id: routeTo.params.client_id,
                        item: item,
                        products: products.items,
                        history: history.items,
                        amount(products) {
                          return products.reduce((amount, product) => {
                            return Math.round(
                              amount +
                                parseFloat(product.price) *
                                  parseInt(product.count)
                            );
                          }, 0.0);
                        },
                      },
                    }
                  );
                });
            });
        });
    },
  },
  {
    path: "/info",
    async: function (routeTo, routeFrom, resolve, reject) {
      this.app.methods
        .get("https://to.gloud.com.ua/ds/info", {
          q: ["order desc add_date"],
        })
        .then((data) => {
          resolve(
            {
              component: info,
            },
            {
              context: {
                items: data.items,
              },
            }
          );
        });
    },
  },
  {
    path: "/calendar",
    async: function (routeTo, routeFrom, resolve, reject) {
      let app = this.app;
      let pss = [];
      let events = [];

      let q = {
        q: [
          "due_date <= " + moment().add(3, "M").format("YYYY-MM-DD"),
          "status_id = 13",
          // "master_id = " + app.data.master_id,
          "order asc due_date",
          "order asc hour",
        ],
      };

      if (app.data.user_role == "master") {
        q.q.push("master_id = " + app.data.master_id);
      }

      pss.push(
        app.methods
          .get("https://to.gloud.com.ua/ds/v_works", q)
          .then((data) => {
            data.items.forEach(function (item, idx) {
              var dt = moment(item.due_date);
              var y = dt.year();
              var m = dt.month();
              var d = dt.date();
              events.push({
                date: new Date(y, m, d),
                hours: item.hour,
                minutes: item.minute,
                title: item.title,
                evt: true,
                work_id: item.id,
                client: item.client,
                address: item.address,
                color: item.in_work_color, //"#2196f3",
              });
            });
          })
      );

      pss.push(
        app.methods
          .get("https://to.gloud.com.ua/notes2", {
            q: ["start >=" + moment().startOf("month").format("YYYY-MM-DD")],
          })
          .then((data) => {
            data.forEach(function (item, idx) {
              var dt = moment(item.start);
              var y = dt.year();
              var m = dt.month();
              var d = dt.date();
              events.push({
                date: new Date(y, m, d),
                title: item.title,
                evt: false,
                color: "red",
              });
            });
          })
      );

      Promise.all(pss).then(() => {
        resolve(
          {
            component: calendar2,
          },
          {
            context: {
              events: events,
            },
          }
        );
      });
    },
  },
  {
    path: "/calendar3",
    async: function (routeTo, routeFrom, resolve, reject) {
      let app = this.app;
      app.methods
        .get("https://to.gloud.com.ua/ds/v_works", {
          q: [
            "due_date <= " + moment().add(3, "M").format("YYYY-MM-DD"),
            "status_id = 13",
            "master_id = " + app.data.master_id,
            "order asc due_date",
            "order asc hour",
          ],
        })
        .then((data) => {
          var cal = {};
          let years = [];
          data.items.forEach(function (item, idx) {
            app.methods
              .get("https://to.gloud.com.ua/notes2", {
                q: ["start = " + item.due_date],
              })
              .then((data) => {});

            let day = moment(item.due_date);
            let y = day.year();
            if (cal[y] == undefined) {
              cal[y] = {
                months: [],
              };
              years.push(y);
            }
            let m = day.month();
            if (cal[y][m] == undefined) {
              cal[y][m] = {
                title: day.format("MMMM"),
                days: {},
                ds: [],
              };
              cal[y].months.push(m);
            }
            let d = day.format("DD ddd");
            if (cal[y][m].days[d] == undefined) {
              cal[y][m].ds.push(d);
              cal[y][m].days[d] = [];
            }
            if (cal[y][m].days[d]) cal[y][m].days[d].push(item);
          });
          resolve(
            {
              component: calendar,
            },
            {
              context: {
                years: years,
                cal: cal,
              },
            }
          );
        });
    },
  },
  {
    path: "/edit/:work_id",
    async: function (routeTo, routeFrom, resolve, reject) {
      let work_id = routeTo.params.work_id;
      this.app.methods
        .get("https://to.gloud.com.ua/ds/v_works/" + work_id, {})
        .then((item) => {
          this.app.methods
            .get("https://to.gloud.com.ua/ds/v_work_products", {
              q: ["works_id = " + work_id],
            })
            .then((products) => {
              resolve(
                {
                  component: edit,
                },
                {
                  context: {
                    work_id: work_id,
                    item: item,
                    products: products.items,
                    products_amount: products.items.reduce(
                      (amount, product) => {
                        return (
                          amount +
                          (product.vip_price > 0
                            ? product.vip_price
                            : product.price) *
                            product.count
                        );
                      },
                      0
                    ),
                  },
                }
              );
            });
        });
    },
  },
  {
    path: "/work/:work_id",
    component: workView,
  },
  {
    path: "/logout",
    async: function (routeTo, routeFrom, resolve, reject) {
      this.app.data.user_id = null;
      this.app.data.master_id = null;
      this.app.data.user_role = null;
      this.app.data.token = null;
      this.app.data.refresh = null;
      this.app.data.exp = null;
      this.app.request.setup({
        headers: {
          "X-Auth-Token": null,
        },
      });
      resolve({
        component: index,
      });
      localStorage.removeItem("gidraData");
      this.app.loginScreen.open("#login-screen");
    },
  },
];

export default routes;
